import type { TextField } from '@payloadcms/plugin-form-builder/types'
import type { FieldErrorsImpl, FieldValues, UseFormRegister } from 'react-hook-form'

import { Input } from '@/components/ui/Input/index'
import { Label } from '@/components/ui/Label/index'
import React from 'react'

import { Error } from '@/blocks/Form/Error'
import { Width } from '@/blocks/Form/Width'

// US phone regex pattern: (123) 456-7890 or 123-456-7890
const US_PHONE_PATTERN = /^(\+1|1)?[-. ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/

export const Phone: React.FC<
  TextField & {
    errors: Partial<FieldErrorsImpl>
    register: UseFormRegister<FieldValues>
    placeholder?: string
  }
> = ({
  name,
  defaultValue,
  errors,
  label,
  register,
  required: requiredFromProps,
  width,
  placeholder = '(123) 456-7890',
}) => {
  return (
    <Width width={width}>
      <Label htmlFor={name}>
        {label}
        {requiredFromProps && <span className="text-destructive ml-1">*</span>}
      </Label>
      <Input
        defaultValue={defaultValue}
        id={name}
        type="tel"
        placeholder={placeholder}
        {...register(name, {
          required: requiredFromProps,
          pattern: {
            value: US_PHONE_PATTERN,
            message: 'Please enter a valid US phone number',
          },
        })}
      />
      {errors[name] && <Error />}
    </Width>
  )
}
