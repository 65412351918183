/**
 * ScrollMotion Component
 * 
 * Handles scroll-based motion for the MediaTrack component.
 * This component is responsible for moving the track based on the user's scroll position.
 */

import React from 'react'
import { motion, useScroll, useTransform } from 'motion/react'
import { cn } from '@/utils/ui'

type ScrollMotionProps = {
  containerRef: React.RefObject<HTMLDivElement | null>
  speed: number
  direction: 'left' | 'right'
  verticalAlignment: 'top' | 'middle' | 'bottom'
  prefersReducedMotion: boolean | null
  children: React.ReactNode
}

export const ScrollMotion: React.FC<ScrollMotionProps> = ({
  containerRef,
  speed,
  direction,
  verticalAlignment,
  prefersReducedMotion,
  children,
}) => {
  // Scroll-based motion setup
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start end', 'end start'],
  })

  const x = useTransform(
    scrollYProgress,
    [0, 1],
    direction === 'left' ? [0, -1000 * speed] : [-1000 * speed, 0],
  )

  return (
    <motion.div 
      style={{ 
        x: prefersReducedMotion ? 0 : x, // Disable motion if user prefers reduced motion
        willChange: 'transform' // Optimize animation performance
      }}
      className={cn(
        'absolute flex items-center',
        verticalAlignment === 'top' && 'top-0',
        verticalAlignment === 'middle' && 'top-1/2 -translate-y-1/2',
        verticalAlignment === 'bottom' && 'bottom-0'
      )}
      aria-live="polite" // Announce changes to screen readers
    >
      <div 
        className="flex items-center"
        role="list" // Semantic role for list of items
        aria-label="Scrollable media items"
      >
        {children}
      </div>
    </motion.div>
  )
}
