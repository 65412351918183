/**
 * MediaCTASection Components
 *
 * This file exports all the specialized components for the MediaCTASection.
 */

// Motion components
export { ParallaxBackground } from './motion/ParallaxBackground'

// Style components
export { ContentLayout } from './styles/ContentLayout'
