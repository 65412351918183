import React, { forwardRef, ElementType } from 'react'
import { cn } from '@/utils/ui'
import { cva, type VariantProps } from 'class-variance-authority'

const containerVariants = cva('relative mx-auto w-full', {
  variants: {
    width: {
      narrow: 'max-w-screen-sm', // ~640px
      content: 'max-w-screen-lg', // ~1024px
      wide: 'max-w-screen-xl', // ~1280px
      full: 'max-w-full',
    },
    padding: {
      none: 'px-0',
      small: 'px-4 md:px-5',
      medium: 'px-4 md:px-6 lg:px-8',
      large: 'px-5 md:px-8 lg:px-12',
    },
  },
  defaultVariants: {
    width: 'content',
    padding: 'medium',
  },
})

type ContainerVariantProps = VariantProps<typeof containerVariants>

type ContainerBaseProps = {
  /** Optional element type to render as. Defaults to 'div' */
  as?: ElementType
  /** Content to be rendered within the container */
  children: React.ReactNode
  /** Optional className for custom styles */
  className?: string
}

export type ContainerProps = ContainerBaseProps &
  Omit<React.HTMLAttributes<HTMLElement>, keyof ContainerBaseProps> &
  ContainerVariantProps

/**
 * Container component to handle horizontal constraints
 * Provides consistent max-width options and horizontal padding
 */
export const Container = forwardRef<HTMLElement, ContainerProps>(
  ({ as: Component = 'div', children, width, padding, className, ...props }, ref) => {
    return (
      <Component
        ref={ref}
        className={cn(
          containerVariants({
            width,
            padding,
          }),
          className,
        )}
        {...props}
      >
        {children}
      </Component>
    )
  },
)

Container.displayName = 'Container'
