'use client'

import React from 'react'
import { Brand, Media } from '@/payload-types'
import { LogoCard } from '@/components/cards/LogoCard'
import { MotionInfinite } from '@/components/motion/MotionInfinite'
import { cn } from '@/utils/ui'

type Props = {
  brands: Brand[]
  className?: string
}

export const SoonLogoTrack: React.FC<Props> = (props) => {
  const { brands, className } = props

  const logos = brands
    .map((brand) => ({
      id: brand.id,
      logo: typeof brand.logo === 'string' ? brand.logo : (brand.logo as Media)?.url || '',
    }))
    .filter((brand) => brand.logo)

  return (
    <div className={cn('relative overflow-hidden', className)}>
      <div
        className="absolute inset-y-0 left-0 z-10 w-24 lg:w-64"
        style={{
          background: 'linear-gradient(to right, var(--color-brand-dark-bg), transparent)',
          opacity: 0.9,
        }}
      />
      <div
        className="absolute inset-y-0 right-0 z-10 w-24 lg:w-64"
        style={{
          background: 'linear-gradient(to left, var(--color-brand-dark-bg), transparent)',
          opacity: 0.9,
        }}
      />
      <MotionInfinite direction="left" speed={40} gap={2} repeat={4}>
        <div className="flex gap-2">
          {logos.map(({ id, logo }, index) => (
            <div key={`${id}-${index}`} className="shrink-0">
              <LogoCard logo={logo} variant="default" />
            </div>
          ))}
        </div>
      </MotionInfinite>
    </div>
  )
}
