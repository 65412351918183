'use client'
import React, { useEffect, useRef, useState } from 'react'
import Image from 'next/image'
import { motion } from 'motion/react'
import type { Media as MediaType } from '@/payload-types'

type Props = {
  logo: MediaType
}

export function Logo(props: Props) {
  const { logo } = props

  const containerRef = useRef<HTMLDivElement | null>(null)
  const [mousePosition, setMousePosition] = useState({ x: 50, y: 50 })

  useEffect(() => {
    const container = containerRef.current
    if (!container) return

    const handleMouseMove = (e: MouseEvent) => {
      const rect = container.getBoundingClientRect()

      // Calculate position directly from clientX/Y for more accurate positioning
      const x = e.clientX - rect.left
      const y = e.clientY - rect.top

      setMousePosition({ x, y })
    }

    container.addEventListener('mousemove', handleMouseMove)

    return () => {
      container.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  return (
    <div
      className="absolute top-0 right-0 h-full w-full overflow-hidden lg:relative lg:w-[800px]"
      ref={containerRef}
    >
      {logo?.url && (
        <div className="absolute inset-0 flex items-end">
          <div className="relative -mb-[20%] h-[140%] w-full">
            <Image
              src={logo.url}
              alt={logo.filename || 'Footer logo'}
              width={1200}
              height={1200}
              className="h-full w-full scale-[2] object-contain opacity-[0.035] md:scale-150 lg:scale-125 lg:opacity-10"
              priority
            />
            <motion.div
              className="pointer-events-none absolute inset-0 hidden lg:block"
              animate={{
                background: `radial-gradient(circle 400px at ${mousePosition.x}px ${mousePosition.y}px, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.90) 100%)`,
              }}
              transition={{
                type: 'tween',
                ease: 'linear',
                duration: 0.05,
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}
