'use client'

import React from 'react'
import { CMSLink } from '@/components/core/CMSLink'
import { FormBlock } from '@/blocks/Form/Component'
import { RichText } from '@/components/core/RichText'
import type { ContactFormSectionBlock as ContactFormSectionBlockType } from '@/payload-types'
import type { Form } from '@payloadcms/plugin-form-builder/types'
import { cn } from '@/utils/ui'

type Props = {
  form: Form
  className?: string
} & Omit<ContactFormSectionBlockType, 'form'>

export const ContactFormSection: React.FC<Props> = (props) => {
  const {
    form,
    description,
    showPlanner = true,
    planner = {
      type: 'custom',
      url: '/project-planner',
      label: 'Go to Project Planner',
      ui: 'primary',
    },
    showEmail = true,
    email = {
      label: 'Hate contact forms?',
      email: 'hello@brewww.studio',
    },
    className,
  } = props

  return (
    <div
      className={cn(
        'col-start-[content-start] col-end-[content-end] py-16 sm:py-24 md:py-32',
        className,
      )}
    >
      <div className="flex w-full flex-wrap justify-between lg:-mt-4">
        <div className="order-1 mb-10 w-full px-2 lg:mb-0 lg:w-[31.25%] lg:pr-3 lg:pl-3 xl:pr-4 xl:pl-4">
          {description && (
            <div className="mb-5 w-full pr-5 text-lg font-light text-zinc-400 lg:max-w-sm lg:pr-0">
              <RichText className="text-body-medium mb-6" data={description} />
            </div>
          )}
          {showPlanner && planner && <CMSLink {...planner} ui={planner.ui || undefined} />}
          {showEmail && email && (
            <div className="text-label-large mt-6 flex w-full flex-col xl:flex-row">
              <div className="font-light text-zinc-400">{email.label}</div>
              <a
                className="text-white xl:relative xl:mr-0 xl:ml-4 xl:inline-block"
                href={`mailto:${email.email}`}
              >
                {email.email}
              </a>
            </div>
          )}
        </div>
        <div className="order-2 mb-10 w-full px-2 lg:mb-0 lg:w-[62.5%] lg:pr-3 lg:pl-3 xl:pr-4 xl:pl-4">
          <FormBlock form={form} enableIntro={false} />
        </div>
      </div>
    </div>
  )
}
