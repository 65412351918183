'use client'

import React from 'react'
import { Media } from '@/components/core/Media'
import { MotionParallax } from '@/components/motion/MotionParallax'
import { cn } from '@/utils/ui'
import type { Media as MediaType } from '@/payload-types'

type IntensityType = 'subtle' | 'balanced' | 'dramatic'

type Props = {
  image: MediaType
  motion: 'none' | 'up' | 'down' | 'fixed'
  intensity: IntensityType
  alt?: string
  prefersReducedMotion?: boolean | null
}

export function ParallaxBackground({
  image,
  motion,
  intensity = 'balanced',
  alt,
  prefersReducedMotion = false,
}: Props) {
  // Use 'none' motion if user prefers reduced motion
  const effectiveMotion = prefersReducedMotion ? 'none' : motion

  // Parallax settings based on intensity
  const getParallaxSettings = () => {
    let depth = 15 // Default value

    if (intensity === 'subtle') depth = 7
    else if (intensity === 'balanced') depth = 15
    else if (intensity === 'dramatic') depth = 25

    const overflowHeightClass =
      intensity === 'subtle' ? 'h-[115%]' : intensity === 'dramatic' ? 'h-[150%]' : 'h-[130%]'

    const insetYClass =
      intensity === 'subtle'
        ? '-inset-y-[7.5%]'
        : intensity === 'dramatic'
          ? '-inset-y-[25%]'
          : '-inset-y-[15%]'

    return {
      depth: effectiveMotion === 'up' ? -depth : depth,
      overflowHeightClass,
      insetYClass,
    }
  }

  // Extract image alt text from the media object or use provided alt
  const imageAlt = alt || image.alt || 'Background image'

  return (
    <div
      className={cn('absolute inset-0', effectiveMotion === 'fixed' && 'fixed')}
      aria-hidden="true" // Hide from screen readers as it's decorative
    >
      {effectiveMotion === 'up' || effectiveMotion === 'down' ? (
        <div className="absolute inset-0">
          <MotionParallax
            depth={getParallaxSettings().depth}
            className="absolute inset-0 h-full w-full"
          >
            <div
              className={`absolute ${getParallaxSettings().insetYClass} right-0 left-0 ${
                getParallaxSettings().overflowHeightClass
              }`}
            >
              <Media
                resource={image}
                fill
                className="object-cover"
                alt={imageAlt}
                priority // Load the background image with priority
              />
              <div className="absolute inset-0 bg-black/40" aria-hidden="true" />
            </div>
          </MotionParallax>
        </div>
      ) : (
        <>
          <Media
            resource={image}
            fill
            className="object-cover"
            alt={imageAlt}
            priority // Load the background image with priority
          />
          <div className="absolute inset-0 bg-black/40" aria-hidden="true" />
        </>
      )}
    </div>
  )
}
