/**
 * AutoMotion Component
 * 
 * Handles automatic motion for the MediaTrack component.
 * This component is responsible for continuously moving the track without user interaction.
 */

import React from 'react'
import { cn } from '@/utils/ui'
import { MotionInfinite } from '@/components/motion/MotionInfinite'

type AutoMotionProps = {
  speed: number
  direction: 'left' | 'right'
  verticalAlignment: 'top' | 'middle' | 'bottom'
  children: React.ReactNode
}

export const AutoMotion: React.FC<AutoMotionProps> = ({
  speed,
  direction,
  verticalAlignment,
  children,
}) => {
  return (
    <div 
      className={cn(
        'absolute w-full',
        verticalAlignment === 'top' && 'top-0',
        verticalAlignment === 'middle' && 'top-1/2 -translate-y-1/2',
        verticalAlignment === 'bottom' && 'bottom-0'
      )}
      aria-live="polite" // Announce changes to screen readers
    >
      <MotionInfinite
        direction={direction}
        speed={speed}
        repeat={4}
        className="w-full"
      >
        <div
          className={cn(
            'flex w-max items-center',
            // When scrolling left-to-right, position content at the start (left)
            direction === 'right' && 'ml-[-200%]',
          )}
        >
          {children}
        </div>
      </MotionInfinite>
    </div>
  )
}
