'use client'

import React from 'react'
import Image from 'next/image'
import { MotionSlider } from '@/components/motion/MotionSlider'
import { cn } from '@/utils/ui'

// Define the types for the block
interface GalleryImage {
  image: string | { url?: string }
  alt: string
}

export interface GallerySliderProps {
  images?: GalleryImage[]
  blockName?: string
}

type Props = {
  className?: string
} & GallerySliderProps

export const GallerySlider: React.FC<Props> = (props) => {
  const { images, className } = props

  if (!images?.length) return null

  const formattedImages = images
    .map((item: GalleryImage) => {
      const imageUrl = typeof item.image === 'string' ? item.image : item.image?.url
      if (!imageUrl) return null
      return {
        src: imageUrl,
        alt: item.alt,
      }
    })
    .filter((item): item is { src: string; alt: string } => item !== null)

  const slides = formattedImages.map((image, index) => (
    <div key={image.src} className="w-full">
      <div className="relative w-full overflow-hidden rounded-xl pt-[56.25%]">
        <Image
          className="h-full w-full object-cover"
          src={image.src}
          alt={image.alt}
          fill
          priority={index === 0}
        />
      </div>
    </div>
  ))

  return (
    <div className={cn('py-12 md:py-16 lg:py-20', className)}>
      <div className="container mx-auto px-4 md:px-6">
        <div className="rounded-3xl bg-neutral-900 p-8 md:p-12">
          <MotionSlider
            showDots={true}
            showArrows={true}
            infinite={true}
            duration={0.5}
            gap={24}
            className="w-full"
          >
            {slides}
          </MotionSlider>
        </div>
      </div>
    </div>
  )
}
