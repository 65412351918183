"use client"

import React, { useEffect, useRef } from 'react'
import { Section } from '@/components/layout'
import { cn } from '@/utils/ui'

// Define the props interface for the Embed component
type EmbedProps = {
  className?: string
  id?: string
  content?: {
    embedCode: string
    description?: string
  }
  settings?: {
    anchor?: string
    height?: string
    containerClass?: string
  }
}

export const Embed: React.FC<EmbedProps> = (props) => {
  const { 
    className, 
    id, 
    content, 
    settings 
  } = props

  // Create a ref for the container where we'll inject the embed code
  const embedContainerRef = useRef<HTMLDivElement>(null)

  // Set defaults for potentially undefined properties
  const contentConfig = content || { embedCode: '' }
  const settingsConfig = settings || {}
  const { description, embedCode } = contentConfig
  const { anchor, height, containerClass } = settingsConfig

  // Effect to inject the embed code
  useEffect(() => {
    if (embedContainerRef.current && embedCode) {
      // Clear any existing content
      embedContainerRef.current.innerHTML = ''
      
      // Create a temporary div to hold the embed code
      const tempDiv = document.createElement('div')
      tempDiv.innerHTML = embedCode
      
      // Extract and execute any script tags
      const scripts = tempDiv.querySelectorAll('script')
      scripts.forEach(oldScript => {
        const newScript = document.createElement('script')
        
        // Copy all attributes from the original script
        Array.from(oldScript.attributes).forEach(attr => {
          newScript.setAttribute(attr.name, attr.value)
        })
        
        // Copy the content of the script
        newScript.innerHTML = oldScript.innerHTML
        
        // Remove the old script and append the new one
        oldScript.parentNode?.removeChild(oldScript)
        tempDiv.appendChild(newScript)
      })
      
      // Append all content to the container
      embedContainerRef.current.appendChild(tempDiv)
    }
  }, [embedCode])

  // If no embed code is provided, don't render anything
  if (!embedCode) return null

  return (
    <Section
      id={anchor || id}
      className={cn(className)}
    >
      <div className="container mx-auto">
        {/* Description - for internal reference only */}
        {description && (
          <div className="sr-only">
            <p>{description}</p>
          </div>
        )}
        
        {/* Embed container */}
        <div 
          ref={embedContainerRef}
          className={cn(
            'w-full mx-auto', 
            containerClass
          )}
          style={height ? { height } : undefined}
          data-embed-container
        />
      </div>
    </Section>
  )
}
