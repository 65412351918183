'use client'

import React from 'react'
import { LogoCard } from '@/components/cards/LogoCard'
import { MotionInfinite } from '@/components/motion/MotionInfinite'

type LogoItem = {
  id: string
  logo: string
}

type DisplaySettings = {
  variant?: 'default' | 'surface' | 'transparent'
  speed?: number
  direction?: 'left' | 'right'
  useDualTracks?: boolean
  pauseOnHover?: boolean
}

type Props = {
  logoItems: LogoItem[]
  displaySettings?: DisplaySettings
  variant?: 'default' | 'surface' | 'transparent'
}

export const LogoTrack: React.FC<Props> = (props) => {
  const { logoItems, displaySettings = {}, variant = 'default' } = props

  const {
    speed = 40,
    direction = 'left',
    useDualTracks = false,
    pauseOnHover = true,
  } = displaySettings

  // Divide logos for dual tracks if enabled
  const topTrackLogos = useDualTracks
    ? logoItems.slice(0, Math.ceil(logoItems.length / 2))
    : logoItems

  const bottomTrackLogos = useDualTracks ? logoItems.slice(Math.ceil(logoItems.length / 2)) : []

  const logoVariant = variant === 'transparent' ? 'surface' : variant

  return (
    <div className="w-full py-8">
      {/* Edge gradient overlays */}
      <div
        className="absolute inset-y-0 left-0 z-10 w-24 lg:w-64"
        style={{
          background:
            variant === 'transparent'
              ? 'linear-gradient(to right, white, transparent)'
              : variant === 'surface'
                ? 'linear-gradient(to right, var(--color-brand-dark-surface), transparent)'
                : 'linear-gradient(to right, var(--color-brand-dark-bg), transparent)',
          opacity: 0.9,
        }}
      />
      <div
        className="absolute inset-y-0 right-0 z-10 w-24 lg:w-64"
        style={{
          background:
            variant === 'transparent'
              ? 'linear-gradient(to left, white, transparent)'
              : variant === 'surface'
                ? 'linear-gradient(to left, var(--color-brand-dark-surface), transparent)'
                : 'linear-gradient(to left, var(--color-brand-dark-bg), transparent)',
          opacity: 0.9,
        }}
      />

      {/* First track */}
      <MotionInfinite
        direction={direction}
        speed={speed}
        gap={2}
        repeat={4}
        pauseOnHover={pauseOnHover}
      >
        <div className="flex gap-2">
          {topTrackLogos.map(({ id, logo }, index) => (
            <div key={`${id}-${index}`} className="shrink-0">
              <LogoCard logo={logo} variant={logoVariant} />
            </div>
          ))}
        </div>
      </MotionInfinite>

      {/* Second track (if dual tracks enabled) */}
      {useDualTracks && bottomTrackLogos.length > 0 && (
        <div className="mt-6 lg:mt-8">
          <MotionInfinite
            direction={direction === 'left' ? 'right' : 'left'}
            speed={speed}
            gap={2}
            repeat={4}
            pauseOnHover={pauseOnHover}
          >
            <div className="flex gap-2">
              {bottomTrackLogos.map(({ id, logo }, index) => (
                <div key={`${id}-${index}`} className="shrink-0">
                  <LogoCard logo={logo} variant={logoVariant} />
                </div>
              ))}
            </div>
          </MotionInfinite>
        </div>
      )}
    </div>
  )
}
