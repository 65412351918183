'use client'

import React, { useRef } from 'react'
import { motion, useScroll, useTransform } from 'motion/react'
import { Section } from '@/components/layout'
import { Media } from '@/components/core/Media'
import { cn } from '@/utils/ui'
import type { MediaRevealBlock as MediaRevealProps } from '@/payload-types'

type Props = {
  className?: string
  id?: string
} & MediaRevealProps

export const MediaRevealClient: React.FC<Props> = (props) => {
  const { media, settings, className } = props
  const sectionRef = useRef(null)

  // Get default values from settings or use fallbacks
  const startScale = settings?.startScale ?? 0.75
  const endScale = settings?.endScale ?? 1
  const imageStartScale = settings?.imageStartScale ?? 2
  const imageEndScale = settings?.imageEndScale ?? 1

  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ['start end', 'center center'],
  })

  const scale = useTransform(scrollYProgress, [0, 1], [startScale, endScale])
  const imageScale = useTransform(scrollYProgress, [0, 1], [imageStartScale, imageEndScale])

  return (
    <Section
      ref={sectionRef}
      className={cn(
        'bg-brand-dark-bg relative h-[80vh] w-full overflow-hidden text-white',
        className,
      )}
    >
      <motion.div className="h-full w-full overflow-hidden" style={{ scale }}>
        <motion.div
          style={{
            position: 'relative',
            height: '100%',
            width: '100%',
            scale: imageScale,
          }}
        >
          {/* The Media component handles both string URLs and Media objects */}
          <Media
            resource={media}
            alt="Background image"
            fill
            htmlElement="div"
            className="relative h-full w-full object-cover object-[50%_30%]"
          />
        </motion.div>
      </motion.div>
    </Section>
  )
}

export default MediaRevealClient
