'use client'

import React, { useState } from 'react'
import type { MediaCTASectionBlock, Media } from '@/payload-types'
import { cn } from '@/utils/ui'
import { ParallaxBackground, ContentLayout } from './components'
import { useReducedMotion } from 'motion/react'

type Props = {
  content: MediaCTASectionBlock['content']
  media: MediaCTASectionBlock['media']
  action: MediaCTASectionBlock['action']
  settings: MediaCTASectionBlock['settings']
}

// Type guard to check if an item's image is a Media object
function isMediaObject(image: unknown): image is Media {
  return typeof image === 'object' && image !== null && 'url' in image
}

export function MediaCTASectionInteractive({ content, media, action, settings }: Props) {
  // Default values for settings
  const motion = settings?.motion || 'none'
  const height = settings?.height || 'medium'
  const intensity = settings?.intensity || 'balanced'

  // Use motion hook for accessibility
  const prefersReducedMotion = useReducedMotion()

  // Track focus state
  const [isFocused, setIsFocused] = useState(false)

  // Height class mapping
  const heightClass = {
    small: 'h-[40vh]',
    medium: 'h-[60vh]',
    large: 'h-[80vh]',
    full: 'h-screen',
  }[height]

  // Ensure media.image is a valid Media object
  if (!isMediaObject(media.image)) {
    return null // Or some fallback UI
  }

  // Create a descriptive section ID
  const sectionId = `media-cta-${content?.eyebrow ? content.eyebrow.toLowerCase().replace(/\s+/g, '-') : 'section'}`

  // Handler for keyboard navigation
  const handleKeyDown = (e: React.KeyboardEvent) => {
    // For escape key to blur focus if needed
    if (e.key === 'Escape') {
      const activeElement = document.activeElement as HTMLElement
      activeElement?.blur()
    }
  }

  return (
    <section
      className={cn('col-[bleed-start/bleed-end] grid grid-cols-subgrid', heightClass)}
      id={sectionId}
      aria-labelledby={`${sectionId}-heading`}
      role="region"
      aria-describedby={`${sectionId}-description`}
      onKeyDown={handleKeyDown}
    >
      <div id={`${sectionId}-description`} className="sr-only">
        Media section with {content?.eyebrow || ''} featuring a background image and call to action
      </div>
      <ParallaxBackground
        image={media.image}
        motion={motion}
        intensity={intensity}
        alt={media.image.alt || `Background for ${content?.eyebrow || 'section'}`}
        prefersReducedMotion={prefersReducedMotion}
      />
      <ContentLayout
        content={content}
        action={action}
        onFocusChange={setIsFocused}
        _isSectionFocused={isFocused}
        sectionId={sectionId}
      />
    </section>
  )
}
