'use client'

import React, { useState } from 'react'
import { RichText } from '@/components/core/RichText'
import { CMSLink } from '@/components/core/CMSLink'
import { Text } from '@/components/ui'
import type { MediaCTASectionBlock } from '@/payload-types'

type Props = {
  content: MediaCTASectionBlock['content']
  action: MediaCTASectionBlock['action']
  onFocusChange?: (isFocused: boolean) => void
  _isSectionFocused?: boolean
  sectionId?: string
}

export function ContentLayout({ content, action, onFocusChange, sectionId }: Props) {
  // Track which element is focused
  const [focusedElement, setFocusedElement] = useState<string | null>(null)

  // Generate unique IDs for focusable elements
  const headingId = `${sectionId}-heading`
  const eyebrowId = `${sectionId}-eyebrow`
  const actionId = `${sectionId}-action`

  // Handle focus events
  const handleFocus = (elementId: string) => {
    setFocusedElement(elementId)
    onFocusChange?.(true)
  }

  const handleBlur = () => {
    setFocusedElement(null)
    onFocusChange?.(false)
  }

  // Determine if this specific element has focus
  const _isFocused = (elementId: string) => focusedElement === elementId

  return (
    <div
      className="relative z-10 col-[content-start/content-end] flex h-full items-center"
      role="region"
      aria-label={content?.eyebrow || 'Media content section'}
    >
      <div className="w-full">
        <div className="flex flex-col text-center text-white">
          {content?.eyebrow && (
            <Text
              id={eyebrowId}
              size="label-medium"
              className="mb-4 uppercase"
              aria-level={2}
              role="heading"
              tabIndex={0}
              onFocus={() => handleFocus(eyebrowId)}
              onBlur={handleBlur}
            >
              {content.eyebrow}
            </Text>
          )}
          <div
            id={headingId}
            className="mx-auto mb-8 max-w-3xl"
            aria-level={1}
            role="heading"
            tabIndex={0}
            onFocus={() => handleFocus(headingId)}
            onBlur={handleBlur}
          >
            <RichText data={content.title} />
          </div>

          {/* Action */}
          {action.type === 'link' && action.link && (
            <div
              id={actionId}
              onFocus={() => handleFocus(actionId)}
              onBlur={handleBlur}
              tabIndex={-1}
            >
              <CMSLink
                {...action.link.link}
                ui={action.link.link.ui || undefined}
                className="inline-flex items-center justify-center rounded-full bg-white px-6 py-3 text-sm font-medium text-black transition-colors hover:bg-white/90 focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-black focus:outline-none"
                aria-label={`${action.link.link.label || 'Learn more'}`}
              >
                {action.link.link.label}
              </CMSLink>
            </div>
          )}

          {action.type === 'video' && action.video?.source && (
            <button
              id={actionId}
              className="inline-flex items-center justify-center rounded-full bg-white px-6 py-3 text-sm font-medium text-black transition-colors hover:bg-white/90 focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-black focus:outline-none"
              onClick={() => {
                // Video modal opening logic would go here
                console.log('Open video:', action.video?.source)
              }}
              aria-label="Watch video"
              aria-haspopup="dialog"
              onFocus={() => handleFocus(actionId)}
              onBlur={handleBlur}
            >
              Watch Video
              <span className="sr-only">Opens in a modal window</span>
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
