'use client'

import { type ReactNode, useRef } from 'react'
import { motion, useScroll, useTransform } from 'motion/react'
import { cn } from '@/utils/ui'

export type MotionParallaxProps = {
  children: ReactNode
  className?: string
  /**
   * Depth of parallax effect in percentage.
   * Higher values = more movement
   * Negative values move in opposite direction
   * @default 10
   */
  depth?: number
  /**
   * Offset from the top of the viewport to start the effect
   * @default 0
   */
  offset?: number
  /**
   * Whether to disable the parallax effect
   * @default false
   */
  disabled?: boolean
}

export const MotionParallax: React.FC<MotionParallaxProps> = ({
  children,
  className,
  depth = 10,
  offset = 0,
  disabled = false,
}) => {
  // Create ref for the parallax container
  const ref = useRef<HTMLDivElement>(null)

  // Get scroll progress for this element
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: [`${offset}px start`, `${offset}px end`],
  })

  // Transform scroll progress into y translation percentage
  const y = useTransform(scrollYProgress, [0, 1], [`-${depth}%`, '0%'])

  // If disabled, render children without parallax effect
  if (disabled) {
    return <div className={className}>{children}</div>
  }

  return (
    <motion.div
      ref={ref}
      className={cn('relative will-change-transform', className)}
      style={{
        y,
      }}
    >
      {children}
    </motion.div>
  )
}
