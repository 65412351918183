import React, { forwardRef, ElementType } from 'react'
import { cn } from '@/utils/ui'
import { cva, type VariantProps } from 'class-variance-authority'

const sectionVariants = cva('relative w-full', {
  variants: {
    paddingTop: {
      none: 'pt-0',
      xsmall: 'pt-6 sm:pt-6 md:pt-6',
      small: 'pt-8 sm:pt-10 md:pt-12',
      medium: 'pt-16 sm:pt-20 md:pt-24',
      large: 'pt-24 sm:pt-28 md:pt-36',
      xlarge: 'pt-32 sm:pt-40 md:pt-48',
    },
    paddingBottom: {
      none: 'pb-0',
      xsmall: 'pb-6 sm:pb-6 md:pb-6',
      small: 'pb-8 sm:pb-10 md:pb-12',
      medium: 'pb-16 sm:pb-20 md:pb-24',
      large: 'pb-24 sm:pb-28 md:pb-36',
      xlarge: 'pb-32 sm:pb-40 md:pb-48',
    },
  },
  defaultVariants: {
    paddingTop: 'medium',
    paddingBottom: 'medium',
  },
})

type SectionVariantProps = VariantProps<typeof sectionVariants>

type SectionBaseProps = {
  /** Optional element type to render as. Defaults to 'section' */
  as?: ElementType
  /** Content to be rendered within the section */
  children: React.ReactNode
  /** Optional className for custom styles */
  className?: string
}

export type SectionProps = SectionBaseProps &
  Omit<React.HTMLAttributes<HTMLElement>, keyof SectionBaseProps> &
  SectionVariantProps

/**
 * Section component to handle vertical spacing
 * Provides consistent padding options with responsive scaling
 * - xsmall: 1.5rem (consistent across breakpoints)
 * - small: 3rem at desktop, scaling down at smaller breakpoints
 * - medium: 6rem at desktop, scaling down at smaller breakpoints
 * - large: 9rem at desktop, scaling down at smaller breakpoints
 * - xlarge: 12rem at desktop, scaling down at smaller breakpoints
 */
export const Section = forwardRef<HTMLElement, SectionProps>(
  (
    { as: Component = 'section', children, paddingTop, paddingBottom, className, ...props },
    ref,
  ) => {
    return (
      <Component
        ref={ref}
        className={cn(
          sectionVariants({
            paddingTop,
            paddingBottom,
          }),
          className,
        )}
        {...props}
      >
        {children}
      </Component>
    )
  },
)

Section.displayName = 'Section'
