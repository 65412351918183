/**
 * NaturalStyle Component
 * 
 * Handles the "natural" style for the MediaTrack component.
 * This style allows for more varied image dimensions for a more organic appearance.
 */

import React, { useState } from 'react'
import { cn } from '@/utils/ui'
import { Media as MediaComponent } from '@/components/core/Media'
import type { Media } from '@/payload-types'

type NaturalStyleProps = {
  items: Array<{
    image: Media
    aspectRatio: 'portrait' | 'landscape'
  }>
  gapClass: string
  handleKeyDown: (e: React.KeyboardEvent, index: number) => void
}

// Dimensions for the natural style using relative units
const dimensions = {
  portrait: {
    // Base width in rem (360px ≈ 22.5rem at 16px base font size)
    baseWidth: 22.5,
    // Responsive classes using relative units
    class: 'w-[22.5rem] sm:w-[20rem] md:w-[22.5rem] lg:w-[22.5rem] xl:w-[24rem]',
    // Maintain aspect ratio
    aspect: 'aspect-[2/3]',
    // For sizes attribute calculation
    sizes: {
      sm: 20,   // Small screens (640px and below)
      md: 22.5, // Medium screens (768px and up)
      lg: 22.5, // Large screens (1024px and up)
      xl: 24,   // Extra large screens (1280px and up)
    },
  },
  landscape: {
    // Base width in rem (540px ≈ 34rem at 16px base font size)
    baseWidth: 34,
    // Responsive classes using relative units
    class: 'w-[34rem] sm:w-[28rem] md:w-[32rem] lg:w-[34rem] xl:w-[36rem]',
    // Maintain aspect ratio
    aspect: 'aspect-[3/2]',
    // For sizes attribute calculation
    sizes: {
      sm: 28, // Small screens
      md: 32, // Medium screens
      lg: 34, // Large screens
      xl: 36, // Extra large screens
    },
  },
}

export const NaturalStyle: React.FC<NaturalStyleProps> = ({
  items,
  gapClass,
  handleKeyDown,
}) => {
  // State to track keyboard focus
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null)

  return items.map((item, index) => {
    const image = typeof item.image === 'string' ? null : item.image
    if (!image?.url) return null

    const isPortrait = item.aspectRatio === 'portrait'
    const aspectRatio = isPortrait ? 'portrait' : 'landscape'

    // Generate an appropriate sizes attribute based on responsive breakpoints
    const imageSize = `(max-width: 640px) min(${dimensions[aspectRatio].sizes.sm}rem, 90vw), 
                      (max-width: 768px) min(${dimensions[aspectRatio].sizes.md}rem, 80vw), 
                      (max-width: 1024px) min(${dimensions[aspectRatio].sizes.lg}rem, 40vw), 
                      ${dimensions[aspectRatio].sizes.xl}rem`
    
    // Get image alt text or generate descriptive text
    const imageAlt = image.alt || `${isPortrait ? 'Portrait' : 'Landscape'} image ${index + 1}`

    return (
      <div
        id={`track-image-${index}`}
        key={`${image.id}-${index}`}
        className={cn(
          'relative shrink-0 overflow-hidden rounded-sm',
          gapClass,
          dimensions[aspectRatio].class,
          dimensions[aspectRatio].aspect,
          'shadow-md transition-shadow duration-300 hover:shadow-lg',
          focusedIndex === index && 'ring-2 ring-primary ring-offset-2',
        )}
        role="img"
        aria-label={imageAlt}
        tabIndex={0} // Make focusable for keyboard navigation
        onKeyDown={(e) => handleKeyDown(e, index)}
        onFocus={() => setFocusedIndex(index)}
        onBlur={() => setFocusedIndex(null)}
      >
        <MediaComponent
          resource={image}
          fill
          sizes={imageSize}
          className="h-full w-full object-cover"
          alt={imageAlt}
          priority={index < 2} // Prioritize loading for the first two images
        />
      </div>
    )
  })
}
