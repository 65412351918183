'use client'

import React from 'react'
import { Title } from '@/components/ui/Title'
import { motion, useScroll, useTransform, MotionValue } from 'motion/react'
import { useRef } from 'react'
import { Section } from '@/components/layout'
import { Media } from '@/components/core/Media'
import type { GalleryOrbitBlock as GalleryOrbitProps, Media as MediaType } from '@/payload-types'

// Define Props type with className
type Props = {
  className?: string
} & GalleryOrbitProps

// Define OrbitImage props type
type OrbitImageProps = {
  image: string | MediaType
  index: number
  scrollYProgress: MotionValue<number>
  direction: 'up' | 'down'
  intensity: 'low' | 'medium' | 'high'
}

// OrbitImage component
const OrbitImage = (props: OrbitImageProps) => {
  const { image, index, scrollYProgress, direction, intensity } = props

  // Define fixed position classes based on the index
  const positionClasses = [
    'absolute top-[10%] right-auto bottom-auto left-0 h-40 w-56', // 0: Top Left
    'absolute top-0 right-auto bottom-auto left-[49%] h-40 w-56', // 1: Middle Left
    'absolute top-[19%] right-0 bottom-auto left-auto h-40 w-56', // 2: Top Right
    'absolute top-[81%] right-[17%] bottom-auto left-auto h-40 w-56', // 3: Bottom Right
    'absolute top-[70%] right-auto bottom-auto left-[17%] h-40 w-56', // 4: Bottom Left
  ]

  // Get position class based on index (with fallback)
  const positionClass = index < positionClasses.length ? positionClasses[index] : positionClasses[0]

  // Determine intensity based on the intensity prop
  let intensityValue = 125 // medium (default)
  if (intensity === 'low') intensityValue = 75
  else if (intensity === 'medium') intensityValue = 125
  else if (intensity === 'high') intensityValue = 200

  // Calculate the transform value based on direction and intensity
  const transformValue = direction === 'up' ? -intensityValue : intensityValue

  // Create the transform for the specific image
  const y = useTransform(scrollYProgress, [0, 1], [0, transformValue])

  return (
    <motion.div
      initial={{ y: 0 }}
      style={{ y }}
      transition={{ type: 'spring', stiffness: 100 }}
      className={`${positionClass} overflow-hidden rounded-md`}
    >
      <Media resource={image} fill className="object-cover rounded-md" />
    </motion.div>
  )
}

export const GalleryOrbit: React.FC<Props> = (props) => {
  const {
    content,
    media,
    settings,
    className,
    id: _id,
    blockName: _blockName,
    blockType: _blockType,
    ...rest
  } = props

  const sectionRef = useRef<HTMLDivElement>(null)
  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ['start end', 'end start'],
  })

  // Get border color and opacity
  const borderColor = settings?.borderColor || 'border-brand-gold'
  const borderOpacity = settings?.borderOpacity || '10'

  // Create opacity transform for the logo
  const logoOpacity = useTransform(scrollYProgress, [0, 0.5], [0, 0.05])

  return (
    <Section
      paddingTop="medium"
      paddingBottom="medium"
      ref={sectionRef}
      className={`relative overflow-hidden ${className || ''}`}
      {...rest}
    >
      <div className="relative m-auto w-full max-w-[120.00rem] flex-col overflow-hidden px-8 pt-24 pb-48 font-medium">
        <div className="relative m-auto flex max-h-[93.00rem] min-h-screen w-full max-w-[100.50rem] items-center justify-center py-96">
          <div className="absolute top-1/2 left-1/2 flex w-full -translate-x-1/2 -translate-y-1/2 items-center justify-center">
            <div className="relative inline-block">
              <div
                className={`${borderColor} absolute inset-0 rounded-full border-2 opacity-[0.${borderOpacity}]`}
                style={{
                  width: '75vw',
                  height: '75vw',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              ></div>
              <motion.div
                initial={{ opacity: 0 }}
                style={{ opacity: logoOpacity }}
                transition={{ type: 'spring', stiffness: 100 }}
                className="inline-block"
              >
                {media?.logoMark && (
                  <Media
                    resource={media.logoMark}
                    className="h-auto w-[50vw] max-w-full rounded-md align-middle"
                    alt={typeof media.logoMark === 'object' ? media.logoMark.alt || 'Logo' : 'Logo'}
                  />
                )}
              </motion.div>
            </div>
          </div>
          <div className="absolute top-0 right-0 bottom-0 left-0 z-2">
            {media?.images &&
              media.images.map(
                (item: { image: string | MediaType; id?: string | null }, index: number) => (
                  <OrbitImage
                    key={index}
                    image={item.image}
                    index={index}
                    scrollYProgress={scrollYProgress}
                    direction="up" // Default to "up" since it's not in the type
                    intensity="medium" // Default to "medium" since it's not in the type
                  />
                ),
              )}
          </div>
          <div className="text-display-small relative z-10 w-full max-w-[69.25rem] leading-none">
            {content?.title && <Title size="display-small">{content.title}</Title>}
          </div>
        </div>
      </div>
    </Section>
  )
}
