'use client'

import Image from 'next/image'
import { Title } from '@/components/ui/Title'
import { MotionParallax } from '@/components/motion/MotionParallax'

interface SoonWorkCardProps {
  imageUrl: string
  title: string
  href: string
  snippet?: string
  className?: string
}

export function SoonWorkCard({ imageUrl, title, snippet, className = '' }: SoonWorkCardProps) {
  return (
    <div
      className={`relative flex h-full w-full flex-col content-start items-start justify-start gap-3 self-start justify-self-start overflow-hidden ${className}`}
    >
      <div className="h-full w-full">
        <div className="relative h-full w-full overflow-hidden rounded-sm">
          <MotionParallax depth={-10} className="absolute inset-0">
            <div className="relative -inset-y-[20%] h-[140%]">
              <Image src={imageUrl} alt={title} fill className="object-cover" />
            </div>
          </MotionParallax>
        </div>
      </div>
      <div className="flex w-full flex-col content-start items-start justify-start text-stone-100">
        <div className="flex-1">
          <Title el="h3" size="title-medium" className="inline text-white">
            {title}
          </Title>
          {snippet && (
            <Title el="p" size="title-medium" className="inline text-white opacity-30">
              {' '}
              {snippet}
            </Title>
          )}
        </div>
      </div>
    </div>
  )
}
