/**
 * MediaTrack Client Component
 *
 * Interactive media track with configurable motion, direction, and styling.
 * Uses specialized sub-components for different track styles and motion types.
 * See ROADMAP.md for the consolidated improvement plan and progress tracking.
 */

'use client'

// React imports
import React, { useRef } from 'react'

// Animation imports
import { useReducedMotion } from 'motion/react'

// Type imports
import type { MediaTrackBlock as MediaTrackProps, Media } from '@/payload-types'

// Component imports - specialized sub-components
import { ScrollMotion, AutoMotion, UniformStyle, NaturalStyle } from './components'
import { cn } from '@/utils/ui'

type Props = {
  className?: string
  items: ValidItem[]
  settings: MediaTrackProps['settings'] & {
    verticalAlignment?: 'top' | 'middle' | 'bottom'
  }
}

type ValidItem = {
  image: Media
  aspectRatio: 'portrait' | 'landscape'
}

// Motion types
type MotionType = 'scroll' | 'auto'

const speedMap = {
  slow: { scroll: 0.5, auto: 20 },
  medium: { scroll: 1, auto: 30 },
  fast: { scroll: 1.5, auto: 40 },
} as const

// Responsive gap classes using relative units
const gapClasses = {
  small: 'mr-3 sm:mr-3 md:mr-4 lg:mr-4 xl:mr-5',
  medium: 'mr-6 sm:mr-8 md:mr-10 lg:mr-12 xl:mr-14',
  wide: 'mr-10 sm:mr-12 md:mr-16 lg:mr-20 xl:mr-24',
} as const

// Define dimensions based on aspect ratio and style
// This helps determine the actual display size of images
// TODO: Add vertical alignment options (top, middle, bottom) for natural flow
// Default should be middle, but users should be able to choose where images align vertically
const _dimensionConfig = {
  uniform: {
    portrait: {
      width: 320,
      class: 'w-[320px]',
      aspect: 'aspect-[2/3]',
    },
    landscape: {
      width: 480,
      class: 'w-[480px]',
      aspect: 'aspect-[3/2]',
    },
  },
  natural: {
    portrait: {
      width: 360,
      class: 'w-[360px]',
      aspect: 'aspect-[2/3]',
    },
    landscape: {
      width: 540,
      class: 'w-[540px]',
      aspect: 'aspect-[3/2]',
    },
  },
}

export function MediaTrackInteractive(props: Props) {
  const { items, settings, className } = props
  const motionType = (settings.motionType as MotionType) || 'scroll'
  const containerRef = useRef<HTMLDivElement>(null)
  const speed = speedMap[settings.speed][motionType]
  const gapClass = gapClasses[settings.gap]
  const styleType = settings.style || 'uniform'
  const verticalAlignment = settings.verticalAlignment || 'middle'

  // Accessibility: Check for user's reduced motion preference
  const prefersReducedMotion = useReducedMotion()

  // Adjust speed or disable animation based on reduced motion preference
  const accessibleSpeed = prefersReducedMotion ? 0 : speed

  // Keyboard navigation handler
  const handleKeyDown = (e: React.KeyboardEvent, index: number) => {
    if (e.key === 'ArrowRight') {
      e.preventDefault()
      const nextIndex = (index + 1) % items.length
      const nextElement = document.getElementById(`track-image-${nextIndex}`)
      nextElement?.focus()
    } else if (e.key === 'ArrowLeft') {
      e.preventDefault()
      const prevIndex = (index - 1 + items.length) % items.length
      const prevElement = document.getElementById(`track-image-${prevIndex}`)
      prevElement?.focus()
    }
  }

  // Render the appropriate style component based on the styleType
  const renderStyleComponent = () => {
    if (styleType === 'natural') {
      return <NaturalStyle items={items} gapClass={gapClass} handleKeyDown={handleKeyDown} />
    }
    return <UniformStyle items={items} gapClass={gapClass} handleKeyDown={handleKeyDown} />
  }

  return (
    <div
      ref={containerRef}
      className={cn(
        'relative h-auto max-h-[70vh] min-h-[40vh] w-full overflow-hidden py-16 sm:max-h-[75vh] sm:min-h-[45vh] sm:py-20 md:max-h-[80vh] md:min-h-[50vh] md:py-24',
        className,
      )}
      role="region"
      aria-label="Media Track Gallery"
      aria-describedby="mediaTrackDescription"
    >
      <div id="mediaTrackDescription" className="sr-only">
        A scrollable gallery of {items.length} images moving{' '}
        {settings.direction === 'left' ? 'from right to left' : 'from left to right'} at{' '}
        {settings.speed === 'fast' ? 'high' : settings.speed === 'slow' ? 'low' : 'medium'} speed
      </div>
      {motionType === 'auto' ? (
        <AutoMotion
          speed={accessibleSpeed}
          direction={settings.direction}
          verticalAlignment={verticalAlignment}
        >
          {renderStyleComponent()}
        </AutoMotion>
      ) : (
        <ScrollMotion
          containerRef={containerRef}
          speed={speed}
          direction={settings.direction}
          verticalAlignment={verticalAlignment}
          prefersReducedMotion={prefersReducedMotion}
        >
          {renderStyleComponent()}
        </ScrollMotion>
      )}
    </div>
  )
}
