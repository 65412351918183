'use client'

import React from 'react'
import { Section } from '@/components/layout'
import type { MediaSectionBlock as MediaSectionProps } from '@/payload-types'
import { Media } from '@/components/core/Media'
import { MotionParallax } from '@/components/motion/MotionParallax'
import { cn } from '@/utils/ui'

type Props = {
  id?: string
} & MediaSectionProps

// Define intensity type to use throughout the component
type IntensityType = 'subtle' | 'balanced' | 'dramatic'

export const MediaSection: React.FC<Props> = (props) => {
  const { id, settings, media } = props

  // Default values for settings
  const layout = settings?.layout || 'fullWidth'
  const height = settings?.height || 'medium'
  const motion = settings?.motion || 'none'

  // Safely access intensity with proper type checking and default
  const getIntensity = (): IntensityType => {
    if (!settings?.intensity) return 'balanced'

    if (
      settings.intensity === 'subtle' ||
      settings.intensity === 'balanced' ||
      settings.intensity === 'dramatic'
    ) {
      return settings.intensity
    }

    return 'balanced'
  }

  const intensity = getIntensity()

  // Height class mapping
  const heightClass = {
    small: 'h-[40vh]',
    medium: 'h-[60vh]',
    large: 'h-[80vh]',
    full: 'h-screen',
  }[height]

  // Parallax settings based on intensity
  const getParallaxSettings = () => {
    // Use static values for consistency
    let depth = 15 // Default value

    // Simple mapping with fall-through safety
    if (intensity === 'subtle') depth = 7
    else if (intensity === 'balanced') depth = 15
    else if (intensity === 'dramatic') depth = 25

    // Use hardcoded classes for reliable rendering
    const overflowHeightClass =
      intensity === 'subtle' ? 'h-[115%]' : intensity === 'dramatic' ? 'h-[150%]' : 'h-[130%]'

    const insetYClass =
      intensity === 'subtle'
        ? '-inset-y-[7.5%]'
        : intensity === 'dramatic'
          ? '-inset-y-[25%]'
          : '-inset-y-[15%]'

    return {
      depth: motion === 'up' ? -depth : depth,
      overflowHeightClass,
      insetYClass,
    }
  }

  // Render media with motion effects
  const renderMedia = () => {
    // For fixed motion (sticky on scroll)
    if (motion === 'fixed') {
      return (
        <div className={cn('relative overflow-hidden', heightClass, motion === 'fixed' && 'fixed')}>
          <Media resource={media?.image} fill className="object-cover" />
        </div>
      )
    }

    // For parallax motion (up/down)
    if (motion === 'up' || motion === 'down') {
      const { depth, overflowHeightClass, insetYClass } = getParallaxSettings()

      return (
        <div className={`relative overflow-hidden ${heightClass}`}>
          <MotionParallax depth={depth} className="absolute inset-0 h-full w-full">
            <div className={`absolute ${insetYClass} right-0 left-0 ${overflowHeightClass}`}>
              <Media resource={media?.image} fill className="object-cover" />
            </div>
          </MotionParallax>
        </div>
      )
    }

    // Default (no motion)
    return (
      <div className={`relative overflow-hidden ${heightClass}`}>
        {media?.image && <Media resource={media.image} fill className="object-cover" />}
      </div>
    )
  }

  // Map layout options to grid column spans based on page-grid-layout
  const gridColumnClass = {
    fullWidth: 'col-[bleed-start/bleed-end]',
    offsetLeft: 'col-[bleed-start/content-end]', // Spans columns 1-3
    offsetRight: 'col-[content-start/bleed-end]', // Spans columns 3-5
  }[layout]

  return (
    <Section
      id={id}
      paddingTop="none"
      paddingBottom="none"
      // Apply grid and subgrid, spanning the full bleed area of the parent grid
      className="col-[bleed-start/bleed-end] grid grid-cols-subgrid"
    >
      {/* Media container using the new grid column span relative to the subgrid */}
      <div className={cn('relative', gridColumnClass)}>{renderMedia()}</div>
    </Section>
  )
}
