'use client'

import React from 'react'
import Image from 'next/image'
import { Section } from '@/components/layout'
import { RichText } from '@/components/core/RichText'
import { CMSLink } from '@/components/core/CMSLink'
import { cn } from '@/utils/ui'
import { MotionParallax } from '@/components/motion/MotionParallax'
import type { JHSplitContentBlock as JHSplitContentProps } from '@/payload-types'
import type { Media } from '@/payload-types'

type Props = {
  className?: string
  id?: string
} & JHSplitContentProps

// Define the intensity type
type IntensityType = 'subtle' | 'balanced' | 'dramatic'

export const JHSplitContent: React.FC<Props> = (props) => {
  const { className, media: mediaConfig, content: contentConfig, settings = {} } = props

  // Set defaults for potentially undefined properties
  const media = mediaConfig || {}
  const content = contentConfig || {}
  const { motion = 'none', intensity = 'balanced' } = settings

  const {
    mediaPosition = 'left',
    // These variables are no longer used with the new grid layout
    // mediaHeight = 'medium',
    // mediaContainment = 'contained',
    media: mediaItem,
  } = media

  const mediaData = typeof mediaItem === 'string' ? null : (mediaItem as Media)

  if (!mediaData?.url) return null

  const isLeftMedia = mediaPosition === 'left'
  // We're using a grid layout now, so we don't need these variables anymore

  // We're using fixed heights with the grid layout instead of dynamic classes

  // Get intensity with proper type checking
  const getIntensity = (): IntensityType => {
    if (intensity === 'subtle' || intensity === 'balanced' || intensity === 'dramatic') {
      return intensity
    }
    return 'balanced'
  }

  // Parallax settings based on intensity
  const getParallaxSettings = () => {
    // Depth percentages for different intensities
    let depth = 15 // Default value for balanced

    // Set depth based on intensity
    if (getIntensity() === 'subtle') depth = 7
    else if (getIntensity() === 'balanced') depth = 15
    else if (getIntensity() === 'dramatic') depth = 25

    return {
      depth: motion === 'up' ? -depth : depth, // Negative for upward motion
    }
  }

  const { depth } = getParallaxSettings()

  return (
    <Section
      className={cn('relative bg-white py-6 text-black md:py-8 lg:py-16 xl:py-24', className)}
    >
      {/* Background layer with grid */}
      <div className="absolute top-0 left-0 z-0 h-full w-full">
        <div className="grid h-full grid-cols-8 grid-rows-[48rem] md:grid-cols-8 lg:grid-cols-12 xl:grid-cols-12">
          <div
            className={cn(
              'relative h-full overflow-hidden',
              isLeftMedia
                ? 'col-span-7 col-start-2 md:col-span-7 md:col-start-2 lg:col-span-8 lg:col-start-2 xl:col-span-8 xl:col-start-2'
                : 'col-span-7 col-start-1 md:col-span-7 md:col-start-2 lg:col-span-8 lg:col-start-3 xl:col-span-8 xl:col-start-3',
            )}
          >
            <div className="absolute top-0 left-0 h-full w-full bg-zinc-100" />
          </div>
        </div>
      </div>

      {/* Main content grid */}
      <div className="relative z-10 grid grid-cols-8 grid-rows-[auto] items-center gap-4 md:grid-cols-8 md:grid-rows-[auto] md:gap-6 lg:grid-cols-12 lg:grid-rows-1 lg:gap-8 xl:grid-cols-12 xl:grid-rows-1 xl:gap-12">
        {/* Content section */}
        <div
          className={cn(
            'relative',
            isLeftMedia
              ? 'order-2 col-span-8 md:order-2 md:col-span-8 lg:order-2 lg:col-span-4 lg:col-start-9 xl:order-2 xl:col-span-4 xl:col-start-9'
              : 'order-2 col-span-8 md:order-2 md:col-span-8 lg:order-1 lg:col-span-4 lg:col-start-2 xl:order-1 xl:col-span-4 xl:col-start-2',
          )}
        >
          <div className="relative grid grid-cols-1 grid-rows-[auto] gap-4 lg:gap-8">
            <div className="px-5 md:px-5 lg:px-0 xl:px-0">
              <div>
                {content.eyebrow && (
                  <div className="inline-flex items-center">
                    <div className="h-1.5 w-1.5 rounded-full bg-black" />
                    <div className="ml-2 font-light text-black">{content.eyebrow}</div>
                  </div>
                )}

                {content.title && (
                  <h3 className="text-4xl md:text-5xl leading-none text-black">{content.title}</h3>
                )}

                {content.content && (
                  <div className="prose prose-sm lg:prose-base mt-4 w-full text-black">
                    <RichText data={content.content} />
                  </div>
                )}

                {content.links && content.links.length > 0 && (
                  <div className="mt-6 flex flex-wrap text-black">
                    {content.links.map((linkItem, i) => {
                      const linkData = linkItem.link || {}
                      return (
                        <div key={`link-${i}`} className="mr-4 inline-flex">
                          <CMSLink {...linkData} ui={linkData.ui || 'link'} />
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Media section */}
        <div
          className={cn(
            'relative',
            isLeftMedia
              ? 'order-1 col-span-8 md:order-1 md:col-span-8 lg:order-1 lg:col-span-7 lg:col-start-1 xl:order-1 xl:col-span-7 xl:col-start-1'
              : 'order-1 col-span-8 md:order-1 md:col-span-8 lg:order-2 lg:col-span-7 lg:col-start-6 xl:order-2 xl:col-span-7 xl:col-start-6',
          )}
        >
          <span className="relative">
            {motion === 'up' || motion === 'down' ? (
              // Parallax motion (up/down)
              <MotionParallax depth={depth} className="absolute inset-0 h-full w-full">
                <div className="relative h-80 w-full max-w-6xl overflow-hidden rounded-lg md:h-96 lg:h-[36rem]">
                  <Image
                    src={mediaData.url}
                    alt={mediaData.alt || ''}
                    fill
                    className="object-cover"
                    sizes="(min-width: 1024px) 57.09rem, 100vw"
                  />
                </div>
              </MotionParallax>
            ) : (
              // No motion
              <div className="relative h-80 w-full max-w-6xl md:h-96 lg:h-[36rem]">
                <Image
                  src={mediaData.url}
                  alt={mediaData.alt || ''}
                  fill
                  className="object-cover"
                  sizes="(min-width: 1024px) 57.09rem, 100vw"
                />
              </div>
            )}
          </span>
        </div>
      </div>

      {/* Responsive styles handled through Tailwind classes */}
    </Section>
  )
}
